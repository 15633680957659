import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spell-list"></a><h2>Spell List</h2>
    <a id="paladin-spells"></a><h3>PALADIN SPELLS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a id="first"></a><a id="first-level-paladin-spells"></a><h5>1ST-LEVEL PALADIN SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bless">Bless</a>:</td>
          <td> Allies gain +1 on attack rolls and +1 on saves against
fear.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bless-water">Bless Water</a>:</td>
          <td> Makes holy water.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bless-weapon">Bless Weapon</a>:</td>
          <td> Weapon strikes true against evil foes.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-water">Create Water</a>:</td>
          <td> Creates 2 gallons/level of pure water.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-light-wounds">Cure Light
Wounds</a>:</td>
          <td> Cures 1d8 damage +1/level (max +5).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-poison">Detect Poison</a>:</td>
          <td> Detects poison in one creature or small object.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-undead">Detect Undead</a>:</td>
          <td> Reveals undead within 60 ft.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#divine-favor">Divine Favor</a>:</td>
          <td> You gain +1 per three levels on attack and damage
rolls.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#endure-elements">Endure
Elements</a>:</td>
          <td> Exist comfortably in hot or cold environments.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-weapon">Magic Weapon</a>:</td>
          <td> Weapon gains +1 bonus.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-chaos">Protection
from Chaos</a>/<a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-evil">Evil</a>:</td>
          <td> +2 to AC and saves, counter mind control, hedge out
elementals and outsiders.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#read-magic">Read Magic</a>:</td>
          <td> Read scrolls and spellbooks.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resistance">Resistance</a>:</td>
          <td> Subject gains +1 on saving throws.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#lesser-restoration">Restoration,
Lesser</a>:</td>
          <td> Dispels magical ability penalty or repairs 1d4 ability
damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#virtue">Virtue</a>:</td>
          <td> Subject gains 1 temporary hp.</td>
        </tr>
      </tbody>
    </table>
    <a id="second"></a><a id="second-level-paladin-spells"></a><h5>2ND-LEVEL PALADIN SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bulls-strength">Bull&rsquo;s
Strength</a>:</td>
          <td> Subject gains +4 to Str for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#delay-poison">Delay Poison</a>:</td>
          <td> Stops poison from harming subject for 1 hour/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#eagles-splendor">Eagle&rsquo;s
Splendor</a>:</td>
          <td> Subject gains +4 to Cha for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#owls-wisdom">Owl&rsquo;s Wisdom</a>:</td>
          <td> Subject gains +4 to Wis for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-paralysis">Remove
Paralysis</a>:</td>
          <td> Frees one or more creatures from paralysis or slow
effect.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resist-energy">Resist Energy</a>:</td>
          <td> Ignores 10 (or more) points of damage/attack from
specified energy type.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shield-other">Shield Other</a><sup>
F</sup>:</td>
          <td> You take half of subject&rsquo;s damage.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#undetectable-alignment">Undetectable
Alignment</a>:</td>
          <td> Conceals alignment for 24 hours.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#zone-of-truth">Zone of Truth</a>:</td>
          <td> Subjects within range cannot lie.</td>
        </tr>
      </tbody>
    </table>
    <a id="third"></a><a id="third-level-paladin-spells"></a><h5>3RD-LEVEL PALADIN SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-moderate-wounds">Cure
Moderate Wounds</a>:</td>
          <td> Cures 2d8 damage +1/level (max +10).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#daylight">Daylight</a>:</td>
          <td> 60-ft. radius of bright light.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#discern-lies">Discern Lies</a>:</td>
          <td> Reveals deliberate falsehoods.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-magic">Dispel Magic</a>:</td>
          <td> Cancels spells and magical effects.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heal-mount">Heal Mount</a>:</td>
          <td> As heal on warhorse or other special mount.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-chaos">Magic
Circle against Chaos</a>:</td>
          <td> As protection from chaos, but 10-ft. radius and 10
min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-evil">Magic
Circle against Evil</a>:</td>
          <td> As protection from evil, but 10-ft. radius and 10
min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#greater-magic-weapon">Magic
Weapon, Greater</a>:</td>
          <td> +1 bonus/four levels (max +5).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prayer">Prayer</a>:</td>
          <td> Allies +1 bonus on most rolls, enemies &ndash;1
penalty.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-blindness/deafness">Remove
Blindness/Deafness</a>:</td>
          <td> Cures normal or magical conditions.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-curse">Remove Curse</a>:</td>
          <td> Frees object or person from curse.</td>
        </tr>
      </tbody>
    </table>
    <a id="fourth"></a><a id="fourth-level-paladin-spells"></a><h5>4TH-LEVEL PALADIN SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#break-enchantment">Break
Enchantment</a>:</td>
          <td> Frees subjects from enchantments, alterations, curses,
and petrification.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-serious-wounds">Cure Serious
Wounds</a>:</td>
          <td> Cures 3d8 damage +1/level (max +15).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#death-ward">Death Ward</a>:</td>
          <td> Grants immunity to death spells and negative energy
effects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-chaos">Dispel Chaos</a>:</td>
          <td> +4 bonus against attacks by chaotic creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-evil">Dispel Evil</a>:</td>
          <td> +4 bonus against attacks by evil creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-sword">Holy Sword</a>:</td>
          <td> Weapon becomes +5, deals +2d6 damage against evil.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mark-of-justice">Mark of
Justice</a>:</td>
          <td> Designates action that will trigger curse on subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#neutralize-poison">Neutralize
Poison</a>:</td>
          <td> Immunizes subject against poison, detoxifies venom in
or on subject.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#restoration">Restoration</a><sup>
M</sup>:</td>
          <td> Restores level and ability score drains.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      